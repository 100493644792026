<template>
  <div class="container">
    <table-page dataName="prizes"
                ref="table"
                :params="{activity_id:$route.query.id}"
                :request="getMemberAppointmentGoods">
      <template slot="button">
        <el-button type="primary"
                   v-permission="['ADMIN']"
                   @click="$refs.setGood.showDialog()">添加商品</el-button>
      </template>
      <el-table-column prop="created_at"
                       show-overflow-tooltip
                       label="商品图片">
        <template slot-scope="scope">
          <TableImg :url="scope.row.img_data ? scope.row.img_data.url :''" />
        </template>
      </el-table-column>
      <el-table-column prop="no"
                       show-overflow-tooltip
                       label="商品编号">
      </el-table-column>
      <el-table-column prop="gift_id"
                       show-overflow-tooltip
                       label="商品ID">
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="礼品类型">
        <template slot-scope="scope">
          {{scope.row.type | goodType}}
        </template>
      </el-table-column>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="商品名称">
      </el-table-column>
      <el-table-column label="商品单价"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          {{scope.row.money/100}}元
        </template>
      </el-table-column>
      <el-table-column label="邮储卡支付价"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          {{scope.row.psbc_card_money/100}}元
        </template>
      </el-table-column>
      <el-table-column prop="quantity"
                       show-overflow-tooltip
                       label="库存数量">
      </el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text"
                     class="mr-15"
                     @click="$refs.setGood.showDialog(scope)">编辑</el-button>
          <el-button type="text"
                     class="mr-15"
                     @click="delGood(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </table-page>
    <SetGood ref="setGood"
             :aid="$route.query.id"
             @on-success="$refs.table.fetch()" />
  </div>
</template>

<script>
import TableImg from '@/components/TableImg'
import SetGood from './components/SetGood'
import TablePage from '@/components/TablePage'
import { getMemberAppointmentGoods, detMemberAppointmentGood } from '@/api/market'
export default {
  name: 'MemberAppointmentGoods',
  data () {
    return {
      getMemberAppointmentGoods
    }
  },
  components: {
    TablePage,
    SetGood,
    TableImg
  },
  filters: {
    goodType (type) {
      const prizeTypes = {
        COUPON: '代金券',
        VERIFY: '网点核销',
        KIND: '邮寄实物',
        INVITE_CARD: '邀请卡'
      }
      return prizeTypes[type] || ''
    }
  },
  methods: {
    delGood (id) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await detMemberAppointmentGood({
          activity_id: this.$route.query.id,
          id: id
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
