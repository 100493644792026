<template>
  <div class="">
    <el-dialog title="设置商品"
               :close-on-click-modal="false"
               :visible.sync="dialogVisible"
               width="50%">
      <el-form :model="form"
               :rules="rules"
               label-position="top"
               ref="form">
        <el-form-item label="礼品id"
                      prop="gift_id">
          <el-input type="text"
                    placeholder="礼品id"
                    v-model="form.gift_id"></el-input>
        </el-form-item>
        <el-form-item label="礼品类型"
                      prop="type">
          <el-select v-model="form.type"
                     :disabled="form.id?true:false"
                     class="width100"
                     placeholder="请选择">
            <el-option v-for="(item,index) in prizeTypes"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品名称"
                      prop="name">
          <el-input type="text"
                    placeholder="商品名称"
                    v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="商品单价"
                      prop="money">
          <el-input type="text"
                    placeholder="商品单价"
                    v-model="form.money"></el-input>
        </el-form-item>

        <el-form-item label="邮储卡支付价"
                      prop="psbc_card_money">
          <el-input type="text"
                    placeholder="请输入邮储卡支付价"
                    v-model="form.psbc_card_money"></el-input>
        </el-form-item>
        <el-form-item label="库存数量"
                      prop="quantity">
          <el-input type="text"
                    placeholder="请输入库存数量"
                    v-model.number="form.quantity"></el-input>
        </el-form-item>
        <el-form-item label="每用户可购次数"
                      prop="user_buy_num">
          <el-input type="text"
                    placeholder="每用户可购次数"
                    v-model.number="form.user_buy_num"></el-input>
        </el-form-item>
        <el-form-item label="商品图片"
                      prop="img_id">
          <ImgUpload :fileList="imgList"
                     v-model="form.img_id" />
        </el-form-item>
        <el-form-item label="代金券id"
                      v-if="form.type === 'COUPON'"
                      prop="coupon_id">
          <el-select v-model="form.coupon_id"
                     filterable
                     class="width100"
                     remote
                     placeholder="请输入批次号"
                     :remote-method="remoteMethod">
            <el-option v-for="item in options"
                       :key="item.id"
                       :label="item.id"
                       :value="item.id">
              {{item.title}}
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="按钮名称"
                      prop="button_name"
                      v-if="form.type==='COUPON'">
          <el-input type="text"
                    placeholder="按钮名称"
                    v-model="form.button_name"></el-input>
        </el-form-item>
        <el-form-item label="小程序APPID"
                      prop="app_id"
                      v-if="form.type==='COUPON'">
          <el-input type="text"
                    placeholder="小程序APPID"
                    v-model="form.app_id"></el-input>
        </el-form-item>
        <el-form-item label="路径"
                      prop="url"
                      v-if="form.type==='COUPON'">
          <el-input type="text"
                    placeholder="路径"
                    v-model="form.url"></el-input>
        </el-form-item>
        <el-form-item label="排序"
                      prop="index">
          <el-input type="text"
                    placeholder="排序"
                    v-model.number="form.index"></el-input>
        </el-form-item>
        <el-form-item class="tr">
          <el-button type="primary"
                     class="mt-15"
                     @click="submitForm('form')">立即{{form.id!==''?'修改':'创建'}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { setMemberAppointmentGood, fetchVouchers } from '@/api/market'
import ImgUpload from '@/components/ImgUpload'
export default {
  props: ['aid'],
  data () {
    return {
      dialogVisible: false,
      imgList: [],
      options: [],
      form: {
        id: '',
        gift_id: '',
        name: '',
        quantity: 0,
        img_id: '',
        money: 0,
        psbc_card_money: 0,
        user_buy_num: 0,
        index: '',
        type: '',
        app_id: '',
        url: '',
        coupon_id: '',
        button_name: ''
      },
      rules: {
        gift_id: [
          { required: true, message: '请输入礼品id', trigger: 'blur' }
        ],
        user_buy_num: [
          { required: true, message: '请输入每用户可购次数', trigger: 'blur' }
        ],
        money: [
          { required: true, message: '请输入红包金额', trigger: 'blur' }
        ],
        psbc_card_money: [
          { required: true, message: '请输入邮储卡支付价', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入奖品名称', trigger: 'blur' }
        ],
        quantity: [
          { required: true, message: '请输入奖品数量', trigger: 'blur' }
        ],
        img_id: [
          { required: true, message: '请上传奖品图片', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请选择礼品类型', trigger: 'change' }
        ],
        coupon_id: [
          { required: true, message: '请选择代金券', trigger: 'change' }
        ],
        app_id: [
          { required: true, message: '请输入奖品数量', trigger: 'blur' }
        ],
        button_name: [
          { required: true, message: '请输入按钮名称', trigger: 'change' }
        ]
      },
      prizeTypes: [
        {
          value: 'COUPON',
          label: '代金券'
        },
        {
          value: 'VERIFY',
          label: '网点核销'
        },
        {
          value: 'KIND',
          label: '邮寄实物'
        },
        {
          value: 'INVITE_CARD',
          label: '邀请卡'
        }
      ]
    }
  },
  components: {
    ImgUpload
  },
  methods: {
    async remoteMethod (query) {
      if (query !== '') {
        const res = await fetchVouchers({
          coupon_stock_id: query
        })
        if (res.meta.code === 0) {
          this.options = res.data.coupons
        }
      } else {
        this.options = []
      }
    },
    showDialog (scoped) {
      this.dialogVisible = true
      if (scoped && scoped.row) {
        const formData = JSON.parse(JSON.stringify(scoped.row))
        formData.img_data && (formData.img_id = formData.img_data.id)
        if (formData.img_data) {
          this.imgList.splice(0, 1, formData.img_data)
        }
        formData.money = formData.money / 100
        formData.psbc_card_money = formData.psbc_card_money / 100

        this.form = formData
      } else {
        this.form = {
          id: '',
          gift_id: '',
          name: '',
          quantity: 0,
          img_id: '',
          money: 0,
          psbc_card_money: 0,
          user_buy_num: 0,
          index: '',
          button_name: ''
        }
        this.imgList = []
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const formData = JSON.parse(JSON.stringify(this.form))
          formData.psbc_card_money = formData.psbc_card_money * 100
          formData.money = formData.money * 100

          const res = await setMemberAppointmentGood({
            activity_id: this.aid, ...formData
          })
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '新增'}成功`,
              type: 'success'
            })
            this.dialogVisible = false
            this.$emit('on-success')
          } else {
            this.$alert(res.meta.msg, {
              type: 'error',
              content: res.meta.msg
            })
          }
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
